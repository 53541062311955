<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      :loading="loading"
      @submit="handleSubmit(submit)"
      submit="Apply"
      @close="handleClose"
      button-class="bg-dynamicBackBtn text-white"
      width="width:520px"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          New Payrun
        </p>
      </template>
      <div>
        <div class="">
          <c-select
            placeholder="--Select--"
            v-model="variableMonth"
            :options="month"
            :rules="['required']"
            :disabled="!isSetup"
            variant="w-full"
            label="Payrun Cycle"
          />
        </div>
        <div class="mt-5">
          <p class="mb-3  font-bold">Pay Type</p>
          <radio-button
            :options="payTypeOptions"
            col-span="col-span-6"
            v-if="salariedIsSetup"
            class=" inline-block ml-2 font-bold text-romanSilver"
            v-model="payRunFilter.payType"
          />
        </div>
        <div class="mt-5 ">
          <p class="mb-3 font-bold">Pay Frequency</p>
          <radio-button
            :options="payFrequencyOptions"
            col-span="col-span-12"
            @input="handleFrequencyChange"
            class=" inline-block text-romanSilver font-bold ml-2"
            v-model="payRunFilter.payFrequency"
          />
          <small v-if="!payFrequencyOptions.length" class="text-flame font-bold"
            >You do not have any pay frequency setup</small
          >
        </div>
        <div v-if="payRunFilter.payFrequency !== 'monthly'" class="mt-5">
          <c-select
            placeholder="--Select--"
            v-model="splitPosition"
            :options="positions"
            variant="w-full"
            label="Payment"
          />
        </div>
        <accordion show-divider>
          <template v-slot:title>
            <p class="font-semibold text-flame text-xs uppercase">
              Filter By
            </p>
          </template>
          <div
            class="grid grid-cols-12 gap-4 text-darkPurple"
            style="margin-top:1.125rem"
          >
            <div class="col-span-12">
              <div class="col-span-3">
                <v-select
                  :clearable="false"
                  placeholder="Search employees"
                  label="fname"
                  class="style-select"
                  :reduce="option => option.id"
                  v-model="search"
                  multiple
                  @search="setAllOrgUsers"
                  :options="allOrgUsers"
                  :filterable="false"
                >
                  <template
                    #option="{fname, lname, orgFunction, userDesignation, photo }"
                  >
                    <div class="flex">
                      <span class="mt-2 ">
                        <img
                          class=""
                          :src="photo"
                          v-if="photo"
                          alt="profile pic"
                          style="height: 25px; width: 25px; border-radius: 5px;"
                        />
                        <div
                          style="height: 25px; width: 25px; border-radius: 5px;"
                          class="text-blueCrayola text-xs border text-center font-semibold pt-1"
                          v-else
                        >
                          {{ $getInitials(`${fname} ${lname}`) }}
                        </div>
                      </span>
                      <div class="ml-2 hover:text-white">
                        <div class="font-bold mt-1 text-sm cursor-pointer">
                          {{ fname }} {{ lname }}
                        </div>
                        <div class=" " style="font-size: 10px">
                          <span class="uppercase">{{
                            orgFunction ? orgFunction.name : ""
                          }}</span>
                          <span class="ml-2">{{
                            userDesignation ? `${userDesignation.name}` : ""
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #selected-option="{fname, lname}">
                    {{ fname }} {{ lname }}
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <icon icon-name="caret-down" size="xsm" />
                    </span>
                  </template>
                  <template #list-footer>
                    <li style="text-align: center; font-size: 12px">
                      Type name of employee to search more
                    </li>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-span-12">
              <c-select
                placeholder="--Select--"
                :options="locationData"
                :disabled="search.length > 0"
                @input="check($event, locationData, locationTags)"
                label="Location(s)"
                variant="w-full"
              />
              <div class="flex flex-wrap">
                <div
                  class="flex justify-between p-2 mt-3 mr-2"
                  v-for="(tag, index) in locationTags"
                  :key="index"
                  style="background: #F7F7FF;border-radius: 5px;"
                >
                  <p class="mr-2">
                    {{ tag.name }}
                  </p>
                  <div
                    @click="locationTags.splice(index, 1)"
                    class="cursor-pointer mt-2"
                  >
                    <icon icon-name="close" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12">
              <c-select
                placeholder="--Select--"
                :options="filteredFunctions"
                :disabled="search.length > 0"
                @input="
                  check($event, filteredFunctions, functionTags),
                    functionIds.push($event.toString())
                "
                label="Function(s)"
                variant="w-full"
              />
              <div class="flex flex-wrap">
                <div
                  class="flex justify-between p-2 mt-3 mr-2"
                  v-for="(tag, index) in functionTags"
                  :key="index"
                  style="background: #F7F7FF;border-radius: 5px;"
                >
                  <p class="mr-2">
                    {{ tag.name }}
                  </p>
                  <div
                    @click="
                      functionTags.splice(index, 1),
                        functionIds.splice(index, 1)
                    "
                    class="cursor-pointer mt-2"
                  >
                    <icon icon-name="close" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12">
              <c-select
                placeholder="--Select--"
                :options="setDesignation"
                :disabled="search.length > 0"
                @input="
                  check($event, setDesignation, designationTags),
                    designationIds.push($event.toString())
                "
                label="Designation(s)"
                variant="w-full"
              />
              <div class="flex flex-wrap">
                <div
                  class="flex justify-between p-2 mt-3 mr-2"
                  v-for="(tag, index) in designationTags"
                  :key="index"
                  style="background: #F7F7FF;border-radius: 5px;"
                >
                  <p class="mr-2">
                    {{ tag.name }}
                  </p>
                  <div
                    @click="
                      designationTags.splice(index, 1),
                        designationIds.splice(index, 1)
                    "
                    class="cursor-pointer mt-2"
                  >
                    <icon icon-name="close" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12">
              <c-select
                placeholder="--Select--"
                :options="setLevel"
                :disabled="search.length > 0"
                @input="check($event, setLevel, levelTags)"
                label="Job Level(s)"
                variant="w-full"
              />
              <div class="flex flex-wrap">
                <div
                  class="flex justify-between p-2 mt-3 mr-2"
                  v-for="(tag, index) in levelTags"
                  :key="index"
                  style="background: #F7F7FF;border-radius: 5px;"
                >
                  <p class="mr-2">
                    {{ tag.name }}
                  </p>
                  <div
                    @click="levelTags.splice(index, 1)"
                    class="cursor-pointer mt-2"
                  >
                    <icon icon-name="close" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12">
              <c-select
                placeholder="--Select--"
                :options="employmentTypesArray"
                v-model="employmentType"
                label="Employment Type"
                variant="w-full"
              />
            </div>
          </div>
        </accordion>
      </div>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { clone } from "lodash";
import { ValidationObserver } from "vee-validate";
import "vue-select/dist/vue-select.css";

export default {
  name: "FilterPayrun",
  components: {
    ValidationObserver,
    vSelect: () => import("vue-select"),
    RightSideBar: () => import("@/components/RightSideBar"),
    Accordion: () => import("@/components/Accordion"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    RadioButton: () => import("@/components/RadioButton")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      suspensionReasons: [],
      isExisitingReason: false,
      today: new Date(),
      isSetup: null,
      month: [],
      variableMonth: "",
      search: "",
      positions: [],
      salariedIsSetup: false,
      splitPosition: "",
      locationData: [],
      locationTags: [],
      functionData: [],
      functionTags: [],
      levelData: [],
      levelTags: [],
      functionIds: [],
      designationIds: [],
      designationData: [],
      designationTags: [],
      des: [],
      payRunFilter: {
        effectiveDate: null,
        payType: null,
        payFrequency: null,
        locationIds: [],
        functionIds: [],
        levelIds: [],
        designationIds: []
      },
      loading: true,
      payTypeOptions: [
        {
          name: "Salaried",
          radioName: "payType",
          value: "salaried"
        }
        // { name: "Hourly ", radioName: "payType", value: 'hourly' }
      ],
      payFrequencyOptions: [],
      allOrgUsers: [],
      employmentTypesArray: [],
      employmentType: ""
    };
  },
  computed: {
    filteredFunctions() {
      return this.$store.state.org.getAllFunctions;
    },
    setDesignation() {
      const designationArray = [];
      this.$store.state.org.getAllFunctions.forEach(item => {
        if (this.functionIds.includes(item.id)) {
          designationArray.push(...item.designations);
        }
      });
      if (designationArray.length) {
        return designationArray;
      }

      return [];
    },
    setLevel() {
      const result = [];
      if (this.setDesignation) {
        this.setDesignation.forEach(item => {
          if (this.designationIds.includes(item.id)) {
            result.push(item.level);
          }
        });
      }
      return result;
    }
  },
  methods: {
    async setAllOrgUsers(search, loading) {
      loading(true);
      try {
        const attributes = "id,fname,lname,photo,userId";
        const { data } = await this.$_getAllEmployeesWithQueries(
          `search=${search}&perPage=1000&payrollStatus=active&attributes=${attributes}`
        );
        this.allOrgUsers = data.data.employees;
        loading(false);
      } catch (err) {
        throw new Error(err);
      }
    },
    getOrgEmploymentTypes() {
      this.$_getOrgEmploymentTypes().then(({ data }) => {
        this.employmentTypesArray = data.data;
      });
    },
    handleFrequencyChange(value) {
      this.positions = [];
      if (value === "twice_monthly") {
        for (let i = 1; i <= 2; i++) {
          this.positions.push({
            name: `${this.$getOrdinal(i)} Payment`,
            id: i
          });
        }
        return;
      }
      if (value === "weekly") {
        for (let i = 1; i <= 4; i++) {
          this.positions.push({
            name: `${this.$getOrdinal(i)} Payment`,
            id: i
          });
        }
      }
    },
    getMonths() {
      this.$_getPayrunMonths(`?payType=backpay`).then(res => {
        this.isSetup = res.data.isSetup;

        this.month = res.data.duration.map(item => ({
          name: item.name,
          id: JSON.stringify({ month: item.month, year: item.year })
        }));
      });
    },
    check(itemValue, itemArry, newArry) {
      const value = itemArry.find(element => element.id === itemValue);
      if (value) {
        const isPresent = newArry.find(element => element.id === value.id);
        if (isPresent) {
          return;
        }
        newArry.push({ name: value.name, id: value.id });
      }
    },
    async getDesignations() {
      try {
        const response = await this.$_getDesignations("");
        this.designationData = clone(response.data.designations);
      } catch (error) {
        throw new Error(error);
      }
    },
    async getLevels() {
      try {
        const response = await this.$_getLevels();
        const { levels } = response.data;

        this.levelData = levels;
        this.loading = false;

        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    handleClose() {
      this.payRunFilter = {
        effectiveDate: null,
        payType: null,
        payFrequency: null,
        locationIds: [],
        functionIds: [],
        levelIds: [],
        designationIds: []
      };
      this.$emit("close");
    },
    getIds(arr) {
      const result = [];
      arr.forEach(item => {
        result.push(item.id);
      });
      return result.join(",");
    },
    submit() {
      if (this.payFrequencyOptions.length === 0) {
        this.$toasted.error("Please select a pay frequency OR set up one", {
          duration: "3000"
        });
        return;
      }
      const ObjDate = JSON.parse(this.variableMonth);
      const arrQuery = {
        month: ObjDate.month,
        year: ObjDate.year,
        payType: this.payRunFilter.payType,
        payFrequency: this.payRunFilter.payFrequency,
        splitPosition: this.splitPosition || 1,
        locationId: this.getIds(this.locationTags),
        functionId: this.getIds(this.functionTags),
        levelId: this.getIds(this.levelTags),
        designationId: this.getIds(this.designationTags),
        employeeId: this.search ? this.search.join(",") : "",
        employmentType: this.employmentType || ""
      };
      this.$router.push({ name: "BackPayPayRun", query: arrQuery });
      this.$emit("close");
    },
    formatPayFreqency(frequency) {
      if (frequency === "twice_monthly") {
        return "Twice Monthly";
      }
      if (frequency === "weekly") {
        return "Four Times Monthly";
      }
      if (frequency === "monthly") {
        return "Monthly";
      }
      return frequency;
    },
    getPayFrequencySetup() {
      this.payFrequencyOptions = [];
      this.$_getPaySchedulesSetup()
        .then(({ data }) => {
          this.salariedIsSetup = data.salariedIsSetup;
          const result = data.salaried.map(item => item.name);
          result.forEach(item => {
            this.payFrequencyOptions.push({
              name: this.formatPayFreqency(item),
              radioName: "payFrequency",
              value: item
            });
          });
        })
        .catch(() => {
          this.payFrequencyOptions = [];
          this.$toasted.error(
            "An Error occured, please contact your administrator",
            { duration: 5000 }
          );
        });
    }
  },
  watch: {
    async openModal() {
      if (this.openModal) {
        this.loading = true;
        this.getOrgEmploymentTypes();
        this.getPayFrequencySetup();
        await this.getMonths();
        await this.$store.dispatch("org/getFunction");
        await this.getDesignations();
        await this.getLevels();
        const result = await this.$_getHumanarLocations();
        this.locationData = result.data.outlets;
      }
    }
  }
};
</script>

<style lang="scss"></style>

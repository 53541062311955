<template>
  <div>
    <Table
            :headers="headers"
            :items="paidData"
            style="width:100%; height:auto"
            class="w-full"
            :loading="loading"
            :has-checkbox="false"
            :has-number="false"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.txnDate">
                {{item.data.txnDate}}
              </span>
              <span v-if="item.refNo">
                {{item.data.refNo}}
              </span>
              <span v-if="item.bankName">
                {{item.data.bankName}}
              </span>
              <span v-if="item.accountNo">
                {{item.data.accountNo}}
              </span>
              <span v-if="item.payCycle">
                {{item.data.payCycle}}
              </span>
              <span v-if="item.payrunNo">
                {{item.data.payrunNo}}
              </span>
              <span v-if="item.debitStatus">
                {{item.data.debitStatus}}
              </span>
              <span v-if="item.settlementStatus">
                <span class="partial py-1 px-10">
                  {{item.data.settlementStatus}}
                </span>
             </span>

              <span v-if="item.id">
                <div
                  class="cursor-pointer"
                   @click="$router.push({
                  name:'ViewPayrunCycle',
                  })"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                </div>
              </span>
            </template>
    </Table>
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
import Table from "@scelloo/cloudenly-ui/src/components/table";

export default {
  name: "Paid",
  components: {
    Table,
  },
  data() {
    return {
      currentTab: "Drafts",
      tabs: ["Drafts", "Under Review", "Approved", "Disapproved", "Paid"],
      headers: [
        { title: "TXN Date", value: "txnDate" },
        { title: " PAYMENT REF NO ", value: "refNo" },
        { title: "BANK NAME", value: "bankName" },
        { title: "ACCOUNT NO ", value: "accountNo" },
        { title: "PAY CYCLE", value: "payCycle" },
        { title: "PAYRUN ID NO", value: "payrunNo" },
        { title: "DIRECT DEBIT STATUS", value: "debitStatus" },
        { title: "SETTLEMENT STATUS", value: "settlementStatus" },
        { title: "", value: "id" }
      ],
      payruns: {},
      loading: false,
      paidData: [
        {
          txnDate: "February 1, 2021",
          refNo: "11237",
          bankName: "Wema Bank",
          accountNo: "0123456789",
          payCycle: "February 1, 2021",
          payrunNo: "82927",
          debitStatus: "Failed",
          settlementStatus: "Partial",
           id: true,

        }
      ]
    };
  },

  methods: {
  },

};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}
.partial {
  background: rgba(233, 147, 35, 0.08);
  color:rgba(233, 147, 35, 1);
}
</style>
